import { Group, Text } from "@mantine/core";
import { StatusIcon, StatusNames, Tooltip } from "../../components-ui";

export enum StateName {
  CREATED = "created",
  FINALIZED = "pending_upload",
  UPLOADED = "uploaded",
  DELIVERED = "delivery_complete",
  ERROR = "error",
  EXPIRED = "expired",
  QC_FAILED = "qc_in_progress",
}

const toolTipLabels: { [key: string]: string } = {
  [StateName.CREATED]: 'Delivery has been initialized, awaiting media to be attached',
  [StateName.FINALIZED]: "Media upload is pending",
  [StateName.UPLOADED]: "Media successfully uploaded",
  [StateName.DELIVERED]: "Delivery workflow successfully completed",
  [StateName.ERROR]: "An error occurred in workflow, check logs for cause",
  [StateName.EXPIRED]: "Delivery has been cancelled or has been marked inactive",
  [StateName.QC_FAILED]: "Media failed quality control checks",
}

export const StateCellRenderer = (params: any) => {
  let state = params.data.state;
  if(state === "finalized") state = StateName.FINALIZED;
  if(state === "delivered") state = StateName.DELIVERED;
  if(state === "qc_failed") state = StateName.QC_FAILED;

  const getStatusIcon = (status: StatusNames) => {
    return (
      <Tooltip label={toolTipLabels[status]}>
        <Group>
          <StatusIcon status={status} />
        </Group>
      </Tooltip>
    )
  }
  const renderStateIcon = () => {
    return Object.values(StateName).includes(state) ?
      getStatusIcon(state) : getStatusIcon(StateName.ERROR)
  };
  const renderStateText = () => {
    return <Text>{state?.split('_').join(' ')}</Text>
  };
  return (
    <Group>
      {renderStateIcon()}
      {renderStateText()}
    </Group>
  );
};
